<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="80px">
    <el-form-item label="主表id" prop="mainId">
      <el-input v-model="dataForm.mainId" placeholder="主表id"></el-input>
    </el-form-item>
    <el-form-item label="指标类型" prop="okrType">
      <el-input v-model="dataForm.okrType" placeholder="指标类型"></el-input>
    </el-form-item>
    <el-form-item label="指标目标" prop="okrTarget">
      <el-input v-model="dataForm.okrTarget" placeholder="指标目标"></el-input>
    </el-form-item>
    <el-form-item label="分解月份" prop="resolveMonth">
      <el-input v-model="dataForm.resolveMonth" placeholder="分解月份"></el-input>
    </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer" v-loading="isOnSubmit">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" :disabled="isOnSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getById , saveOrUpdate } from '@/api/dataflow/olxcokrmonthdetail.js'
  export default {
    data () {
      return {
        visible: false,
        isOnSubmit: false,
        dataForm: {
          id: null,
          mainId: '',
          okrType: '',
          okrTarget: '',
          resolveMonth: ''
        },
        dataRule: {
          mainId: [
            { required: true, message: '主表id不能为空', trigger: 'blur' }
          ],
          okrType: [
            { required: true, message: '指标类型不能为空', trigger: 'blur' }
          ],
          okrTarget: [
            { required: true, message: '指标目标不能为空', trigger: 'blur' }
          ],
          resolveMonth: [
            { required: true, message: '分解月份不能为空', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      init (id) {
        this.dataForm.id = id
        this.visible = true
        this.isOnSubmit = false
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            getById(id).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.mainId = data.data.mainId
                this.dataForm.okrType = data.data.okrType
                this.dataForm.okrTarget = data.data.okrTarget
                this.dataForm.resolveMonth = data.data.resolveMonth
              }
            }).catch((err) => {
              this.$message.error(err)
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
          	this.isOnSubmit = true
            const data_ = {
              'id': this.dataForm.id || undefined,
			        'mainId': this.dataForm.mainId,
			        'okrType': this.dataForm.okrType,
			        'okrTarget': this.dataForm.okrTarget,
			        'resolveMonth': this.dataForm.resolveMonth
            }
            saveOrUpdate(data_).then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 500
                })
                this.visible = false
                this.$emit('refreshDataList')
              } else {
                this.$message.error(data.msg)
              }
            }).catch((err) => {
              this.$message.error(err)
            }).finally(()=>{
              this.isOnSubmit = false
            })
          }
        })
      }
    }
  }
</script>
